import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../../components/layout';
import BlogCard from "../../components/BlogCard/index";

import './styles.scss';

function BlogIndex({ location, pageContext: { posts } }) {
  return (
    <div>
      <Helmet>
        <title>Blog | Voa Labs</title>
      </Helmet>
      <Layout location={location} showLanguageSelector={false}>
        <section className="section">
          <div className="container">
            <h1 className="BlogPage__title">Blog</h1>
            <h2 className="BlogPage__sectionTitle">Recent articles</h2>
            <div className="columns BlogPage__columns is-multiline">
              {
                posts.map(post =>
                  <BlogCard post={post} />
                )
              }
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}


export default BlogIndex;